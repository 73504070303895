<template>
  <v-card>
    <v-container
      fluid
      class="py-0"
    >
      <v-row>
        <v-col
          class="text-uppercase primary white--text"
          style="border-top-left-radius: 4px; border-bottom-left-radius: 4px; max-width: 45px; min-width: 45px; font-weight: 500;"
        >
          {{ translationLanguage }}
        </v-col>
        <v-col class="grow py-0">
          <v-container
            fluid
            class="px-0 pb-0"
          >
            <v-row
              align="center"
              dense
            >
              <template v-for="translation in translatedContent">
                <v-col
                  :key="translation.key + '-label'"
                  cols="12"
                  md="3"
                  class="text-capitalize"
                >
                  {{ translation.key }}
                </v-col>
                <v-col
                  :key="translation.key + '-input'"
                  cols="12"
                  md="9"
                >
                  <TipTapRichTextEditor
                    v-model="translation.value"
                    v-if="translation.key === 'description'"
                    style="height: 800px;"
                  />
                  <v-text-field
                    v-else
                    hide-details
                    solo
                    background-color="grey lighten-2"
                    v-model="translation.value"
                    clearable
                    autocomplete="off"
                  />
                </v-col>
              </template>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="px-0 pb-0"
              >
                <v-btn
                  block
                  depressed
                  color="error"
                  text
                  tile
                  style="border-bottom-right-radius: 4px;"
                  @click="remove"
                >
                  {{ $t('clearAll') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import TipTapRichTextEditor from "@/components/common/TipTapRichTextEditor"
export default {
  name: "TranslatedContent",
  components: {TipTapRichTextEditor},
  props: {
    translatedContent: {
      type: Array,
      default: () => []
    },
    translationLanguage: {
      type: String,
      default: ''
    }
  },
  methods: {
    remove() {
      this.$emit('removeTranslations', this.translationLanguage)
    }
  }
}
</script>

<style>
.ProseMirror {
  height: auto;
}
</style>
